import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useRootStore } from "@/stores/Root";
import HomeView from "../views/index/IndexView.vue";
import { checkRouteAccess, saveMenuItemVisit } from "@/services/IndexService";
import { PAGE_TITLE } from "@/composables/constants";

const wrapTitle = (title: string) => ({ title }),
  defaultWrapTitle = PAGE_TITLE,
  routes: Array<RouteRecordRaw> = [
    {
      path: "/",
      name: "home",
      meta: wrapTitle(defaultWrapTitle),
      component: HomeView,
    },
    {
      path: "/auth/login",
      name: "authLogin",
      component: () => import("../views/auth/LoginView.vue"),
    },
    // LogController
    {
      path: "/log/feed",
      name: "logFeed",
      meta: wrapTitle("Логи"),
      component: () => import("../views/log/FeedReport.vue"),
    },
    // ReviewController
    {
      path: "/review/feed",
      name: "reviewFeed",
      meta: wrapTitle("Лента отзывов"),
      component: () => import("../views/review/FeedReport.vue"),
    },
    // StatsController
    {
      path: "/stats/months",
      name: "statsMonths",
      meta: wrapTitle("Продажи"),
      component: () => import("../views/stats/MonthsReport.vue"),
    },
    {
      path: "/stats/revenue_timeline",
      name: "statsRevenueTimeline",
      meta: wrapTitle("Вывод товаров"),
      component: () => import("../views/stats/RevenueTimelineReport.vue"),
    },
    {
      path: "/stats/factory_credit_our_money",
      name: "statsFactoryCreditOurMoney",
      meta: wrapTitle("Кредиторская задолженность у нас"),
      component: () => import("../views/stats/FactoryCreditOurMoneyReport.vue"),
    },
    {
      path: "/stats/factory_cred_prod_orders",
      name: "statsFactoryCredProdOrders",
      meta: wrapTitle("Кредиторская задолженность по срокам"),
      component: () => import("../views/stats/FactoryCredProdOrdersReport.vue"),
    },
    {
      path: "/stats/big_five",
      name: "statsBigFive",
      meta: wrapTitle("Big 5"),
      component: () => import("../views/stats/BigFiveReport.vue"),
    },
    {
      path: "/stats/rev_rent",
      name: "statsRevRent",
      meta: wrapTitle("Rev/Rent"),
      component: () => import("../views/stats/RevRentReport.vue"),
    },
    {
      path: "/stats/price_segments",
      name: "statsPriceSegments",
      meta: wrapTitle("Ценовые сегменты"),
      component: () => import("../views/stats/PriceSegmentsReport.vue"),
    },
    {
      path: "/stats/nomenclature_analysis",
      name: "statsNomenclatyreAnalysis",
      meta: wrapTitle("Номенклатурный анализ"),
      component: () => import("../views/stats/NomenclatureAnalysisReport.vue"),
    },
    {
      path: "/stats/nomenclature_analysis_tree",
      name: "statsNomenclatyreAnalysisTree",
      meta: wrapTitle("Номенклатурный анализ Tree"),
      component: () =>
        import("../views/stats/NomenclatureAnalysisTreeReport.vue"),
    },
    // MaxsalesController
    {
      path: "/maxsales/maxsales",
      name: "maxsalesMaxsales",
      meta: wrapTitle("Maxsales"),
      component: () => import("../views/maxsales/MaxsalesReport.vue"),
    },
    {
      path: "/maxsales/maxsales_tree",
      name: "maxsalesMaxsalesTree",
      meta: wrapTitle("Maxsales Tree"),
      component: () => import("../views/maxsales/MaxsalesTreeReport.vue"),
    },
    {
      path: "/maxsales/maxsales_money",
      name: "maxsalesMaxsalesMoney",
      meta: wrapTitle("Maxsales Деньги"),
      component: () => import("../views/maxsales/MaxsalesMoneyReport.vue"),
    },
    {
      path: "/maxsales/fob_index",
      name: "maxsalesFobIndex",
      meta: wrapTitle("Индекс Fob"),
      component: () => import("../views/maxsales/FobIndexReport.vue"),
    },
    // FactoryController
    {
      path: "/factory/edit/:id",
      name: "factoryEdit",
      meta: wrapTitle("Фабрика"),
      component: () => import("../views/factory/FactoryForm.vue"),
    },
    {
      path: "/factory/contract/create",
      name: "factoryContractCreate",
      meta: wrapTitle("Новый контракт"),
      component: () => import("../views/factory/FactoryContractForm.vue"),
    },
    {
      path: "/factory/contract/:id",
      name: "factoryContractEdit",
      meta: wrapTitle("Редактирование контракта"),
      component: () => import("../views/factory/FactoryContractForm.vue"),
    },
    // ChessController
    {
      path: "/chess/chess",
      name: "chessChess",
      meta: wrapTitle("Chess"),
      component: () => import("../views/chess/ChessReport.vue"),
    },
    {
      path: "/chess/second_coming",
      name: "chessSecondComing",
      meta: wrapTitle("Второе пришествие"),
      component: () => import("../views/chess/SecondComingReport.vue"),
    },
    // CandidateController
    {
      path: "/candidate/board",
      name: "candidateBoard",
      meta: wrapTitle("Образцы"),
      component: () => import("../views/candidate/BoardReport.vue"),
    },
    // Crowd
    {
      path: "/candidate/crowd_casting",
      name: "candidateCrowdCasting",
      meta: wrapTitle("Crowd"),
      component: () => import("../views/candidate/CrowdCastingReport.vue"),
    },
    // Все образцы
    {
      path: "/candidate/product_candidates",
      name: "candidateProductCandidates",
      meta: wrapTitle("Все образцы"),
      component: () => import("../views/candidate/ProductCandidatesGrid.vue"),
    },
    // Категория
    {
      path: "/category/edit/:id",
      name: "categoryEdit",
      meta: wrapTitle("Категория"),
      component: () => import("../views/category/CategoryForm.vue"),
    },
    // Поддержка пользователей
    {
      path: "/user_support/jobs",
      name: "userSupportJobs",
      meta: wrapTitle("Поддержка"),
      component: () => import("../views/support/UserSupportGrid.vue"),
    },
    {
      path: "/user_support/job/create",
      name: "userSupportJobCreate",
      meta: wrapTitle("Новое обращение сотрудника"),
      component: () => import("../views/support/UserSupporForm.vue"),
    },
    {
      path: "/user_support/job/:id",
      name: "userSupportJobEdit",
      meta: wrapTitle("Редактирование обращения сотрудника"),
      component: () => import("../views/support/UserSupporForm.vue"),
    },
    // allsales
    {
      path: "/allsales/allsales",
      name: "allsalesAllsales",
      meta: wrapTitle("Allsales"),
      component: () => import("../views/allsales/AllsalesReport.vue"),
    },
    {
      path: "/allsales/fair_sell_out",
      name: "allsalesFairSellOut",
      meta: wrapTitle("Чистый SellOut"),
      component: () => import("../views/allsales/FairSellOutReport.vue"),
    },
    {
      path: "/allsales/allsales",
      name: "allsalesAllsales",
      meta: wrapTitle("Allsales"),
      component: () => import("../views/allsales/AllsalesReport.vue"),
    },
    {
      path: "/allsales/tree_map",
      name: "allsalesTreeMap",
      meta: wrapTitle("Tree Map"),
      component: () => import("../views/allsales/TreeMapReport.vue"),
    },
    {
      path: "/allsales/avg_day_stats",
      name: "allsalesAvgDayStats",
      meta: wrapTitle("Средние продажи в день"),
      component: () => import("../views/allsales/AvgDayStatsReport.vue"),
    },
    {
      path: "/allsales/sales_stream",
      name: "allsalesSalesStream",
      meta: wrapTitle("Sales Stream"),
      component: () => import("../views/allsales/SalesStreamReport.vue"),
    },
    {
      path: "/allsales/avg_day_pbc",
      name: "allsalesAvgDayPbc",
      meta: wrapTitle("Детализация: пузырьки"),
      component: () => import("../views/allsales/AvgDayPbcReport.vue"),
    },
    // Платежи
    // {
    //   path: "/finance/payment_plan",
    //   name: "financePaymentPlan",
    //   meta: wrapTitle("Платежи"),
    //   component: () => import("../views/finance/PaymentPlanReport.vue"),
    // },
    // Банки
    {
      path: "/finance/banks",
      name: "financeBanks",
      meta: wrapTitle("Банки"),
      component: () => import("../views/finance/BanksGrid.vue"),
    },
    // Прибыль по партнерам
    {
      path: "/finance/profit_by_partners",
      name: "financeProfitByPartners",
      meta: wrapTitle("Прибыль по партнерам"),
      component: () => import("../views/finance/ProfitByPartnersReport.vue"),
    },
    // Агентские платежи
    {
      path: "/finance/agent_payments",
      name: "financeAgentPayments",
      meta: wrapTitle("Агентские платежи"),
      component: () => import("../views/finance/AgentPaymentsReport.vue"),
    },
    // Создать агентский платеж
    {
      path: "/finance/agent_payment/create",
      name: "financeAgentPaymentCreate",
      meta: wrapTitle("Новый агентский платеж"),
      component: () => import("../views/finance/AgentPaymentForm.vue"),
    },
    // Редактировать агентский платеж
    {
      path: "/finance/agent_payment/:id",
      name: "financeAgentPaymentEdit",
      meta: wrapTitle("Редактировать агентский платеж"),
      component: () => import("../views/finance/AgentPaymentForm.vue"),
    },
    // План производства
    {
      path: "/production/plan",
      name: "productionPlan",
      meta: wrapTitle("План производства"),
      component: () => import("../views/production/PlanReport.vue"),
    },
    // Тесты с UI KIT
    {
      path: "/ui_kit",
      name: "uiKit",
      meta: wrapTitle("UI KIT"),
      component: () => import("../views/ui_kit/IndexView.vue"),
    },
    // Реестры
    {
      path: "/marketing/bloggers",
      name: "marketingBloggers",
      meta: wrapTitle("Блогеры"),
      component: () => import("../views/marketing/BloggersGrid.vue"),
    },
    {
      path: "/marketing/blogger/create",
      name: "marketingBloggerCreate",
      meta: wrapTitle("Новый блогер"),
      component: () => import("../views/marketing/BloggerForm.vue"),
    },
    {
      path: "/marketing/blogger/:id",
      name: "marketingBloggerEdit",
      meta: wrapTitle("Редактирование блогера"),
      component: () => import("../views/marketing/BloggerForm.vue"),
    },
    {
      path: "/marketing/adv_reviews",
      name: "marketingAdvReviews",
      meta: wrapTitle("Обзоры"),
      component: () => import("../views/marketing/AdvReviewsGrid.vue"),
    },
    {
      path: "/marketing/adv_review/create",
      name: "marketingAdvReviewCreate",
      meta: wrapTitle("Новый обзор"),
      component: () => import("../views/marketing/AdvReviewForm.vue"),
    },
    {
      path: "/marketing/adv_review/:id",
      name: "marketingAdvReviewEdit",
      meta: wrapTitle("Редактирование обзора"),
      component: () => import("../views/marketing/AdvReviewForm.vue"),
    },
    // http://192.168.1.13:3000/spa/novelty_task/novelty_tasks
    // NoveltyTaskController
    {
      path: "/novelty_task/report",
      name: "noveltyTaskReport",
      meta: wrapTitle("Вывод новинок"),
      component: () => import("../views/novelty_task/ReportReport.vue"),
    },
    {
      path: "/novelty_task/create",
      name: "noveltyTaskCreate",
      meta: wrapTitle("Новая задача по заказу новинок"),
      component: () => import("../views/novelty_task/NoveltyTaskForm.vue"),
    },
    {
      path: "/novelty_task/edit/:id",
      name: "noveltyTaskEdit",
      meta: wrapTitle("Задача по заказу новинок"),
      component: () => import("../views/novelty_task/NoveltyTaskForm.vue"),
    },
    {
      path: "/novelty_task/novelty_tasks",
      name: "noveltyTaskNoveltyTasks",
      meta: wrapTitle("Задачи по заказу новинок"),
      component: () => import("../views/novelty_task/NoveltyTasksGrid.vue"),
    },
    // Сертификаты
    // Реестр Компании по сертификации
    {
      path: "/cert/certification_companies",
      name: "certCertificationCompanies",
      meta: wrapTitle("Компании по сертификации"),
      component: () => import("../views/cert/CertificationCompaniesGrid.vue"),
    },
    {
      path: "/cert/certification_company/create",
      name: "certCertificationCompanyCreate",
      meta: wrapTitle("Новая компания по сертификации"),
      component: () => import("../views/cert/CertificationCompanyForm.vue"),
    },
    {
      path: "/cert/certification_company/:id",
      name: "certCertificationCompanyEdit",
      meta: wrapTitle("Компания по сертификации"),
      component: () => import("../views/cert/CertificationCompanyForm.vue"),
    },
    {
      path: "/cert/certification_company_contact/create",
      name: "certCertificationCompanyContactCreate",
      meta: wrapTitle("Контакт компании по сертификации"),
      component: () =>
        import("../views/cert/CertificationCompanyContactForm.vue"),
    },
    {
      path: "/cert/certification_company_contact/:id",
      name: "certCertificationCompanyContactEdit",
      meta: wrapTitle("Контакт компании по сертификации"),
      component: () =>
        import("../views/cert/CertificationCompanyContactForm.vue"),
    },
    // Реестр Заявки на сертификаты
    {
      path: "/cert/certificate_requests",
      name: "certCertificateRequests",
      meta: wrapTitle("Заявки на сертификаты"),
      component: () => import("../views/cert/CertificateRequestsGrid.vue"),
    },
    {
      path: "/cert/certificate_request/create",
      name: "certCertificateRequestCreate",
      meta: wrapTitle("Новая заявка на сертификат"),
      component: () => import("../views/cert/CertificateRequestForm.vue"),
    },
    {
      path: "/cert/certificate_request/:id",
      name: "certCertificateRequestEdit",
      meta: wrapTitle("Заявка на сертификат"),
      component: () => import("../views/cert/CertificateRequestForm.vue"),
    },
    // Реестр Сертификаты
    {
      path: "/cert/certificates",
      name: "certCertificates",
      meta: wrapTitle("Сертификаты"),
      component: () => import("../views/cert/CertificatesGrid.vue"),
    },
    {
      path: "/cert/create",
      name: "certCreate",
      meta: wrapTitle("Новый сертификат"),
      component: () => import("../views/cert/CertificateForm.vue"),
    },
    {
      path: "/cert/:id(\\d+)",
      name: "certEdit",
      meta: wrapTitle("Редактирование сертификата"),
      component: () => import("../views/cert/CertificateForm.vue"),
    },
    // Реестр Дизайны студий
    {
      path: "/design/designs",
      name: "designDesigns",
      meta: wrapTitle("Дизайны студий"),
      component: () => import("../views/design/DesignsGrid.vue"),
    },
    {
      path: "/design/:id",
      name: "designEdit",
      meta: wrapTitle("Редактирование дизайна"),
      component: () => import("../views/design/DesignForm.vue"),
    },
    // Реестр Mkit: Виды сундуков
    {
      path: "/mkit/chest_types",
      name: "mkitChestTypes",
      meta: wrapTitle("Виды сундуков"),
      component: () => import("../views/mkit/ChestTypesGrid.vue"),
    },
    {
      path: "/mkit/chest_type/create",
      name: "mkitChestTypeCreate",
      meta: wrapTitle("Тип сундука"),
      component: () => import("../views/mkit/ChestTypeForm.vue"),
    },
    {
      path: "/mkit/chest_type/:id",
      name: "mkitChestTypeEdit",
      meta: wrapTitle("Тип сундука"),
      component: () => import("../views/mkit/ChestTypeForm.vue"),
    },
    // Реестр Mkit: Розыгрыши
    {
      path: "/mkit/raffles",
      name: "mkitRaffles",
      meta: wrapTitle("Розыгрыши"),
      component: () => import("../views/mkit/RafflesGrid.vue"),
    },
    {
      path: "/mkit/raffle/:id",
      name: "mkitRaffleEdit",
      meta: wrapTitle("Розыгрыш"),
      component: () => import("../views/mkit/RaffleForm.vue"),
    },
    {
      path: "/mkit/raffle_chest/create",
      name: "mkitRaffleChestCreate",
      meta: wrapTitle("Создание сундука"),
      component: () => import("../views/mkit/RaffleChestForm.vue"),
    },
    {
      path: "/mkit/raffle_chest/:id",
      name: "mkitRaffleChestEdit",
      meta: wrapTitle("Редактирование сундука"),
      component: () => import("../views/mkit/RaffleChestForm.vue"),
    },
    {
      path: "/mkit/prize_claims",
      name: "mkitPrizeClaims",
      meta: wrapTitle("Выигрыши"),
      component: () => import("../views/mkit/PrizeClaimsGrid.vue"),
    },
    {
      path: "/mkit/prize_claim/:id",
      name: "mkitPrizeClaimEdit",
      meta: wrapTitle("Выигрыш"),
      component: () => import("../views/mkit/PrizeClaimForm.vue"),
    },

    // Реестр Mkit: Этапы игры
    {
      path: "/mkit/arcade_stages",
      name: "mkitArcadeStages",
      meta: wrapTitle("Этапы игры"),
      component: () => import("../views/mkit/ArcadeStagesGrid.vue"),
    },
    {
      path: "/mkit/arcade_stage/create",
      name: "mkitArcadeStageCreate",
      meta: wrapTitle("Этап игры"),
      component: () => import("../views/mkit/ArcadeStageForm.vue"),
    },
    {
      path: "/mkit/arcade_stage/:id",
      name: "mkitArcadeStageEdit",
      meta: wrapTitle("Этап игры"),
      component: () => import("../views/mkit/ArcadeStageForm.vue"),
    },

    // Реестр Mkit: Промокоды для игры
    {
      path: "/mkit/arcade_promo_codes",
      name: "mkitArcadePromoCodes",
      meta: wrapTitle("Промокоды для игры"),
      component: () => import("../views/mkit/ArcadePromoCodesGrid.vue"),
    },
    {
      path: "/mkit/arcade_promo_code/create",
      name: "mkitArcadePromoCodeCreate",
      meta: wrapTitle("Промокод для игры"),
      component: () => import("../views/mkit/ArcadePromoCodeForm.vue"),
    },
    {
      path: "/mkit/arcade_promo_code/:id",
      name: "mkitArcadePromoCodeEdit",
      meta: wrapTitle("Промокод для игры"),
      component: () => import("../views/mkit/ArcadePromoCodeForm.vue"),
    },
    // Реестр Чеки на проверку
    {
      path: "/mkit/receipts",
      name: "mkitReceipts",
      meta: wrapTitle("Чеки на проверку"),
      component: () => import("../views/mkit/ReceiptsGrid.vue"),
    },
    {
      path: "/mkit/receipt/:id",
      name: "mkitReceiptEdit",
      meta: wrapTitle("Чек на проверку"),
      component: () => import("../views/mkit/ReceiptForm.vue"),
    },
    // Реестр Проверки артворков
    {
      path: "/artcheck/artchecks",
      name: "artcheckArtchecks",
      meta: wrapTitle("Проверки артворков"),
      component: () => import("../views/artcheck/ArtchecksGrid.vue"),
    },
    // Реесты Инспекции
    {
      path: "/inspection/inspections",
      name: "inspectionInspections",
      meta: wrapTitle("Все инспекции"),
      component: () => import("../views/inspection/InspectionsGrid.vue"),
    },
    // Проверка Партномеров
    {
      path: "/specification_check/specification_checks",
      name: "specificationCheckSpecificationChecks",
      meta: wrapTitle("Проверки P/N"),
      component: () =>
        import("../views/specification_check/SpecificationChecksGrid.vue"),
    },
    // Реестры Запросы артворков
    // Реестр Типы вопросов для запроса артворков
    {
      path: "/artworks_inquiry/item_types",
      name: "artworksInquiryItemTypes",
      meta: wrapTitle("Типы вопросов для запроса артворков"),
      component: () => import("../views/artworks_inquiry/ItemTypesGrid.vue"),
    },
    {
      path: "/artworks_inquiry/item_types/create",
      name: "artworksInquiryItemTypeCreate",
      meta: wrapTitle("Создание типа вопроса для ЗА"),
      component: () => import("../views/artworks_inquiry/ItemTypeForm.vue"),
    },
    {
      path: "/artworks_inquiry/item_types/:id",
      name: "artworksInquiryItemTypeEdit",
      meta: wrapTitle("Редактирование типа вопроса для ЗА"),
      component: () => import("../views/artworks_inquiry/ItemTypeForm.vue"),
    },
    // ProductController
    // Реестр Номенклатура
    {
      path: "/product/products",
      name: "productProducts",
      meta: wrapTitle("Номенклатура"),
      component: () => import("../views/product/ProductsGrid.vue"),
    },
    // Номенклатурная карточка товара
    {
      path: "/product/edit/:id",
      name: "productEdit",
      meta: wrapTitle("Редактирование товара"),
      component: () => import("../views/product/ProductForm.vue"),
    },
    // Отчет по товару
    {
      path: "/product/view/:id",
      name: "productView",
      meta: wrapTitle("О товаре"),
      component: () => import("../views/product/ProductReport.vue"),
    },
    // Варианты товара
    {
      path: "/product/product_variant/:id",
      name: "productProductVariantEdit",
      meta: wrapTitle("Редактирование варианта товара"),
      component: () => import("../views/product/ProductVariantForm.vue"),
    },
    // Ссылки на товар
    {
      path: "/product/product_link/create",
      name: "productProductLinkCreate",
      meta: wrapTitle("Новая ссылка на товар"),
      component: () => import("../views/product/ProductLinkForm.vue"),
    },
    {
      path: "/product/product_link/:id",
      name: "productProductLinkEdit",
      meta: wrapTitle("Редактирование ссылки на товар"),
      component: () => import("../views/product/ProductLinkForm.vue"),
    },
    // TODO
    {
      path: "/product/product_task/create",
      name: "productProductTaskCreate",
      meta: wrapTitle("Новое TODO"),
      component: () => import("../views/product/ProductTaskForm.vue"),
    },
    {
      path: "/product/product_task/:id",
      name: "productProductTaskEdit",
      meta: wrapTitle("Редактирование TODO"),
      component: () => import("../views/product/ProductTaskForm.vue"),
    },
    // Реестр: Брокеры
    {
      path: "/shipment/brokers",
      name: "shipmentBrokers",
      meta: wrapTitle("Брокеры"),
      component: () => import("../views/shipment/BrokersGrid.vue"),
    },
    {
      path: "/shipment/broker/:id",
      name: "shipmentBrokerEdit",
      meta: wrapTitle("Брокер"),
      component: () => import("../views/shipment/BrokerForm.vue"),
    },
    // Контейнер
    {
      path: "/shipment/ship_order/:id",
      name: "shipmentShipOrderEdit",
      meta: wrapTitle("Редактирование контейнера"),
      component: () => import("../views/shipment/ShipOrderForm.vue"),
    },
    // Форма создания Commercial Invoice
    {
      path: "/shipment/commercial_invoice/create",
      name: "shipmentCommercialInvoiceCreate",
      meta: wrapTitle("Редактирование Commercial Invoice"),
      component: () => import("../views/shipment/CommercialInvoiceForm.vue"),
    },
    // Страница перенаправления с инвойса на фабрику
    {
      path: "/shipment/open_factory",
      name: "shipmentOpenFactory",
      meta: wrapTitle("Перенаправление с Commercial Invoice на фабрику"),
      component: () => import("../views/shipment/OpenFactoryView.vue"),
    },
    // Форма редактирования Commercial Invoice
    {
      path: "/shipment/commercial_invoice/:id",
      name: "shipmentCommercialInvoiceEdit",
      meta: wrapTitle("Редактирование Commercial Invoice"),
      component: () => import("../views/shipment/CommercialInvoiceForm.vue"),
    },
    // Реестр Подразделения
    {
      path: "/personel/departments",
      name: "personelDepartments",
      meta: wrapTitle("Подразделения"),
      component: () => import("../views/personel/DepartmentsGrid.vue"),
    },
    {
      path: "/personel/department/create",
      name: "personelDepartmentCreate",
      meta: wrapTitle("Новое подразделение"),
      component: () => import("../views/personel/DepartmentForm.vue"),
    },
    {
      path: "/personel/department/:id",
      name: "personelDepartmentEdit",
      meta: wrapTitle("Редактирование подразделения"),
      component: () => import("../views/personel/DepartmentForm.vue"),
    },
    // Реестр Сотрудники
    {
      path: "/personel/employees",
      name: "personelEmployees",
      meta: wrapTitle("Сотрудники"),
      component: () => import("../views/personel/EmployeesGrid.vue"),
    },
    {
      path: "/personel/employee/:id",
      name: "personelEmployeeEdit",
      meta: wrapTitle("Редактирование сотрудника"),
      component: () => import("../views/personel/EmployeeForm.vue"),
    },
    {
      path: "/personel/employee/create",
      name: "personelEmployeeCreate",
      meta: wrapTitle("Новый сотрудник "),
      component: () => import("../views/personel/EmployeeForm.vue"),
    },
    // Моя карточка
    {
      path: "/cabinet/my_card",
      name: "cabinetMyCard",
      meta: wrapTitle("Моя карточка"),
      component: () => import("../views/personel/EmployeeForm.vue"),
    },
    // Реестр Участники
    {
      path: "/podarok/applicants",
      name: "podarokApplicants",
      meta: wrapTitle("Участники"),
      component: () => import("../views/podarok/ApplicantsGrid.vue"),
    },
    {
      path: "/podarok/applicant/:id",
      name: "podarokApplicantEdit",
      meta: wrapTitle("Участник"),
      component: () => import("../views/podarok/ApplicantForm.vue"),
    },
    // Реестр Обращения
    {
      path: "/podarok/requests",
      name: "podarokRequests",
      meta: wrapTitle("Обращения"),
      component: () => import("../views/podarok/RequestsGrid.vue"),
    },
    {
      path: "/podarok/request/:id",
      name: "podarokRequestEdit",
      meta: wrapTitle("Обращение"),
      component: () => import("../views/podarok/RequestForm.vue"),
    },
    {
      path: "/podarok/requests_report",
      name: "podarokRequestsReport",
      meta: wrapTitle("Проверка обращений"),
      component: () => import("../views/podarok/RequestsReport.vue"),
    },
    // Реестр пользователи
    {
      path: "/system/users",
      name: "systemUsers",
      meta: wrapTitle("Пользователи"),
      component: () => import("../views/system/UsersGrid.vue"),
    },
    {
      path: "/system/user/:id",
      name: "systemUserEdit",
      meta: wrapTitle("Пользователь"),
      component: () => import("../views/system/UserForm.vue"),
    },
    // Регистрация сотрудника
    {
      path: "/system/register_user",
      name: "systemRegisterUser",
      meta: wrapTitle("Регистрация сотрудника"),
      component: () => import("../views/system/RegisterUserView.vue"),
    },
    // Реестр Компьютеры
    {
      path: "/system/computers",
      name: "systemComputers",
      meta: wrapTitle("Компьютеры"),
      component: () => import("../views/system/ComputersGrid.vue"),
    },
    {
      path: "/system/computer/:id",
      name: "systemComputerEdit",
      meta: wrapTitle("Редактирование компьютера"),
      component: () => import("../views/system/ComputerForm.vue"),
    },
    {
      path: "/system/computer/create",
      name: "systemComputerCreate",
      meta: wrapTitle("Новый компьютер"),
      component: () => import("../views/system/ComputerForm.vue"),
    },
    // Реестр Пункты меню
    {
      path: "/system/menu_items",
      name: "systemMenuItems",
      meta: wrapTitle("Пункты меню"),
      component: () => import("../views/system/MenuItemsGrid.vue"),
    },
    {
      path: "/system/menu_item/:id",
      name: "systemMenuItemEdit",
      meta: wrapTitle("Пункт меню"),
      component: () => import("../views/system/MenuItemForm.vue"),
    },
    // Системный журнал
    {
      path: "/system/system_log",
      name: "systemSystemLog",
      meta: wrapTitle("Системный журнал"),
      component: () => import("../views/system/SystemLogGrid.vue"),
    },
    // Proxy
    {
      path: "/system/proxy",
      name: "systemProxy",
      meta: wrapTitle("Proxy"),
      component: () => import("../views/system/ProxyGrid.vue"),
    },
    {
      path: "/system/proxy/create",
      name: "systemProxyCreate",
      meta: wrapTitle("Новый proxy"),
      component: () => import("../views/system/ProxyForm.vue"),
    },
    {
      path: "/system/proxy/:id",
      name: "systemProxyEdit",
      meta: wrapTitle("Редактирование proxy"),
      component: () => import("../views/system/ProxyForm.vue"),
    },
    // Список рассылок
    {
      path: "/system/mail_lists",
      name: "systemMailLists",
      meta: wrapTitle("Список рассылок"),
      component: () => import("../views/system/MailListsGrid.vue"),
    },
    {
      path: "/system/mail_list/create",
      name: "systemMailListCreate",
      meta: wrapTitle("Новая рассылка"),
      component: () => import("../views/system/MailListForm.vue"),
    },
    {
      path: "/system/mail_list/:id",
      name: "systemMailListEdit",
      meta: wrapTitle("Редактирование рассылки"),
      component: () => import("../views/system/MailListForm.vue"),
    },
    // Реестр Сервисные центры
    {
      path: "/hotline/services",
      name: "hotlineServices",
      meta: wrapTitle("Сервисные центры"),
      component: () => import("../views/hotline/ServicesGrid.vue"),
    },
    {
      path: "/hotline/service/:id",
      name: "hotlineServiceEdit",
      meta: wrapTitle("Сервисный центр"),
      component: () => import("../views/hotline/ServiceForm.vue"),
    },
    // Реестр Отправления
    {
      path: "/delivery/delivery_cases",
      name: "deliveryDeliveryCases",
      meta: wrapTitle("Отправления"),
      component: () => import("../views/delivery/DeliveryCasesGrid.vue"),
    },
    {
      path: "/delivery/delivery_case/create",
      name: "deliveryDeliveryCaseCreate",
      meta: wrapTitle("Новое отправление"),
      component: () => import("../views/delivery/DeliveryCaseForm.vue"),
    },
    {
      path: "/delivery/delivery_case/:id",
      name: "deliveryDeliveryCaseEdit",
      meta: wrapTitle("Отправление"),
      component: () => import("../views/delivery/DeliveryCaseForm.vue"),
    },
    // Реестр Доставки
    {
      path: "/delivery/deliveries",
      name: "deliveryDeliveries",
      meta: wrapTitle("Доставки"),
      component: () => import("../views/delivery/DeliveriesGrid.vue"),
    },
    {
      path: "/delivery/:id",
      name: "deliveryDeliveryEdit",
      meta: wrapTitle("Доставка"),
      component: () => import("../views/delivery/DeliveryForm.vue"),
    },
    // Telegram
    // Скидки
    {
      path: "/telegram/discounts",
      name: "telegramDiscounts",
      meta: wrapTitle("Скидки для Telegram"),
      component: () => import("../views/telegram/DiscountsGrid.vue"),
    },
    {
      path: "/telegram/discount/:id",
      name: "telegramDiscountEdit",
      meta: wrapTitle("Редактирование скидки для Telegram"),
      component: () => import("../views/telegram/DiscountForm.vue"),
    },
    {
      path: "/telegram/discount/create",
      name: "telegramDiscountCreate",
      meta: wrapTitle("Новая скидка для Telegram"),
      component: () => import("../views/telegram/DiscountForm.vue"),
    },
    // Тесты с дизайном СЛ сюда
    {
      path: "/als/home",
      name: "alsHome",
      meta: wrapTitle("Виджеты"),
      component: () => import("../views/als/HomeView.vue"),
    },
    // Никуда не попали
    {
      path: "/:pathMatch(.*)*",
      meta: wrapTitle("Ошибка 404"),
      component: () =>
        import(
          /* webpackChunkName: "app.page404" */ "../views/index/Page404View.vue"
        ),
    },
  ];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  }),
  setTitle = function (title: string) {
    window.document.title = title;
    useRootStore().setPageTitle(title);
  };

router.beforeEach((to, from, next) => {
  const title =
    to.meta && to.meta.title && typeof to.meta.title === "string"
      ? to.meta.title
      : defaultWrapTitle;

  useRootStore().setIsSearchOpen(false);
  if (
    to.name != "home" &&
    to.name != "authLogin" &&
    to.fullPath != from.fullPath
  ) {
    // Проверяем, что пользователю доступна страница и он авторизован
    checkRouteAccess(to.path).then((response) => {
      if (response) {
        setTitle(title);
        next();
      } else {
        // У пользователя нет доступа к странице, делаем редирект
        setTitle(defaultWrapTitle);
        next("/");
      }
    });
  } else {
    setTitle(defaultWrapTitle);
    next();
  }
});

router.afterEach((to, from) => {
  if (to.fullPath != from.fullPath) {
    // Проверяем, что пользователь не нажал в пункте меню на этот же пункт
    if (to.name != "home" && to.name != "authLogin") {
      // Сохраняем посещение страницы
      saveMenuItemVisit(to.fullPath);
    }
    // Чистим хранилище, покидая страницу
    useRootStore().resetView();
  }
});

export default router;
